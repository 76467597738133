import React, { useEffect } from 'react';
import axios from 'axios';

import { ISystem } from '../interfaces/system';
import { ITag } from '../interfaces/tag';
import { IFile } from '../interfaces/file';

import apiUrls from '../common/apiUrls';

import TagIndex from '../components/TagIndex';

interface IProps {
  isMobileViewport: boolean,
  customCursorController: any,
  system: ISystem,
  setSystem: Function,
  tags: Array<ITag>,
  setTags: Function,
  currentTag: ITag,
  setCurrentTag: Function,
  isMenuOpen: boolean,
  setIsMenuOpen: Function,
  currentFile: IFile,
  setCurrentFile: Function,
  setCurrentClosestFiles: Function,
  files: Array<IFile>,
  setFiles: Function,
  nextFilePageApiUrl: string,
  setNextFilePageApiUrl: Function,
  loadingFilesApiUrl: string,
  setLoadingFilesApiUrl: Function,
  setIsMobileViewport: Function,
  setCurrentlyHoveredFile: Function,
  resetIsScrolling: Function,
  customColumnsCount: number,
  setCustomColumnsCount: Function
}

const IndexPage = (props: IProps) => {
  const {
    system,
    setSystem,
    tags,
    setTags,
    setCurrentTag,
    setCurrentlyHoveredFile,
  } = props;
  
  useEffect(() => {
    if (!system) {
      axios.get(apiUrls.system.get)
        .then((res) => {
          const systemData = res.data;

          setSystem(systemData);
          setCurrentTag(systemData.startPage);
        })
        .catch((err) => {
          console.log('Error: loading system data', err);
        });
    }
  }, [system]);

  useEffect(() => {
    if (!tags) {
      axios.get(apiUrls.tags.list)
        .then((res) => {
          setTags(res.data);
        })
        .catch((err) => {
          console.log('Error: loading tags data', err);
        });
    }
  }, [tags]);

  return (
    <TagIndex 
      {...props}
    />
  );
}

export default IndexPage;
